<!--
    表格中图片的显示及预览   imgviewer
    @param  {String}   attachments    附件
    @param  {String}   buttonName     铵钮名字
    @param  {String}   imgWrapper     查看图片的类名（防止多个组件的类名冲突）


    <imgViewer :attachments="props.data.payCertificate" :writeStyle='{color:"#035fc2"}' type="but" buttonName="证明" imgWrapper="editWork"/>
    <imgViewer :attachments="companyDetail.qrCodeUrl" :styles="qrImgStyle" :type="'img'" alt="公司二维码" :buttonName="companyDetail.qrCodeUrl" imgWrapper="editWork" style="margin-bottom: 6px" />
    <imgViewer  :attachments='dispatchData.oldAttachments' :type="'imgList'" buttonName='查看' imgWrapper='oldDis'/>

-->

<template>
  <div id='attachments'>
    <div class="imagesView" :class='imgWrapper'  v-viewer="{}" style='display:none'>
      <img v-for="src in attachments.split('##')" :src="src" :key="src">
    </div>
    <img :style="styles" v-if="attachments && type == 'img'" :src="imgUrl"  @click="showViewer"/>

  </div>
</template>

<script>
import loading from "../../../assets/images/loading.gif"
export default {
  name:"imgViewer",
  props:{
    attachments: { type: String, default: '' },
    buttonName: { type: String, default: "" },
    imgWrapper: { type: String, default: 'imagesView' },
    type: '',
    styles: {
      type: Object,
      default: () => {
        return {
          width: '100px',
          height: '100%',
          cursor: 'pointer'
        }
      }
    },
    writeStyle:{ type:Object, default: () => {}}
  },
  data(){
    return {
      imgUrl:loading
    }
  },
  mounted(){

  },
  watch:{
    buttonName:{
      deep:true,
      immediate:true,
      handler(e){
        if(!e) return;
        var newImg = new Image()
        newImg.src = e;
        newImg.onload = () => { // 图片加载成功后把地址给原来的img
        console.log('tag', '')
          this.imgUrl = newImg.src
          e=""
        }
      }
    }
  },
  methods: {
    // 图片预览
    showViewer (idx) {
      if(this.imgUrl!=this.buttonName) return;
      const viewer = this.$el.querySelector('.' + this.imgWrapper).$viewer
      viewer.show()
    }
  },
}
</script>


<style lang="scss"  scoped>
#attachments{
  display:inline-block;
  span{
    display: inline;
    cursor: pointer;
    border-bottom:1px solid transparent;
    height:22px;
    &:hover {
      // color: $theme-color;
      // border-bottom:1px solid $theme-color;
    }
  }
  .underline{
    width: 28px !important;
  }
}
</style>